<template>
  <div class="sidebar">
    <div class="sidebar-logo">
      <b-tooltip
        type="is-info"
        label="Home"
        position="is-bottom"
      >
        <router-link to="/profile">
          <figure class="image is-48x48">
            <img
              width="48"
              height="48"
              src="@/assets/images/logo-sidebar.svg"
              alt="Matrix For CRM"
            >
          </figure>
        </router-link>
      </b-tooltip>
    </div>

    <div class="sidebar-menu">
      <div class="sidebar-menu-list">
        <b-tooltip
          v-if="hasPermissions('read_accounts')"
          type="is-info"
          label="Accounts"
          position="is-right"
        >
          <b-button
            aria-label="View Accounts"
            tag="router-link"
            to="/accounts"
            active-class="is-active"
            type="is-text"
            icon-right="account"
          />
        </b-tooltip>

        <b-tooltip
          v-if="hasPermissions('read_contacts')"
          type="is-info"
          label="Contacts"
          position="is-right"
        >
          <b-button
            aria-label="View Contacts"
            tag="router-link"
            to="/contacts"
            active-class="is-active"
            type="is-text"
            icon-right="account-multiple"
          />
        </b-tooltip>

        <b-tooltip
          v-if="hasPermissions('read_leads')"
          type="is-info"
          label="Leads"
          position="is-right"
        >
          <b-button
            aria-label="View Leads"
            tag="router-link"
            to="/leads"
            active-class="is-active"
            type="is-text"
            icon-right="star"
          />
        </b-tooltip>

        <b-tooltip
          v-if="hasPermissions('read_opportunities')"
          type="is-info"
          label="Opportunities"
          position="is-right"
        >
          <b-button
            aria-label="View Opportunities"
            tag="router-link"
            to="/opportunities"
            active-class="is-active"
            type="is-text"
            icon-right="bullseye"
          />
        </b-tooltip>

        <b-tooltip
          v-if="hasPermissions('read_activities')"
          type="is-info"
          label="Activities"
          position="is-right"
        >
          <b-button
            aria-label="View Activities"
            tag="router-link"
            to="/activities"
            active-class="is-active"
            type="is-text"
            icon-right="playlist-check"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Documents"
          position="is-right"
        >
          <b-button
            aria-label="View Documents"
            tag="router-link"
            to="/documents"
            active-class="is-active"
            type="is-text"
            icon-right="folder"
          />
        </b-tooltip>

        <b-tooltip
          v-if="hasRole('ROLE_BUSINESS_ADMIN')"
          type="is-info"
          label="Organization"
          position="is-right"
        >
          <b-button
            aria-label="Organization"
            tag="router-link"
            to="/organization"
            active-class="is-active"
            type="is-text"
            icon-right="sitemap"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Reports"
          position="is-right"
        >
          <b-button
            aria-label="Reports"
            type="is-text"
            icon-right="chart-line"
            @click="redirectToMxB"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Reset All Grid Filters"
          position="is-right"
        >
          <b-button
            aria-label="Reset All Grid Filters"
            type="is-text"
            icon-right="undo"
            @click="resetAllGrids"
          />
        </b-tooltip>
      </div>

      <div class="sidebar-menu-list">
        <b-tooltip
          v-if="hasRole('ROLE_BUSINESS_ADMIN')"
          type="is-info"
          label="Settings"
          position="is-right"
        >
          <b-button
            aria-label="Settings"
            tag="router-link"
            to="/settings"
            type="is-text"
            icon-right="cog"
          />
        </b-tooltip>

        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <b-dropdown
              aria-role="list"
              position="is-top-right"
            >
              <figure
                slot="trigger"
                class="image is-32x32"
              >
                <img
                  style="width:32px; height:32px;"
                  width="32"
                  height="32"
                  class="is-rounded"
                  :src="profilePic === 'null' ? defaultImage : profilePic"
                  alt="Test"
                >
              </figure>

              <b-dropdown-item
                aria-role="listitem"
                @click="logoutUser"
              >
                Logout
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';
import getConfig from '@/plugins/config';

export default {
  name: 'BaseSidebar',

  data: () => ({
    defaultImage,
    profilePic: localStorage.getItem('user_photo'),
  }),

  methods: {
    async logoutUser() {
      try {
        await this.$store.dispatch('Authentication/logout');
        localStorage.clear();
        await this.$router.push('/');
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },

    redirectToMxB() {
      const token = localStorage.getItem('access_token');
      const name = localStorage.getItem('username');
      const VUE_APP_MXB_SERVER_API_BASE_URL = getConfig('VUE_APP_MXB_SERVER_API_BASE_URL');
      window.open(
        `${VUE_APP_MXB_SERVER_API_BASE_URL}token=${token}&name=${name}&cmd=FWDMXC`,
        '_self',
      );
    },

    resetAllGrids() {
      this.$buefy.dialog.confirm({
        title: 'Resetting All Grid Filters',
        message: 'Are you sure you want to <b>reset</b> all your grids filters? This action cannot be undone.',
        confirmText: 'Reset All Grid Filters',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.$store.dispatch('Grids/resetAllGridFilters');
          this.$buefy.toast.open('Successfully reset all grid filters!');
        },
      });
    },
  },
};
</script>
