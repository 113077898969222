<template>
  <div class="dashboard">
    <aside class="dashboard-navigation">
      <base-sidebar />
    </aside>

    <main
      id="main"
      class="dashboard-content"
      role="main"
    >
      <slot name="content" />
    </main>
  </div>
</template>

<script>
import BaseSidebar from '@/components/BaseSidebar.vue';

export default {
  name: 'DashboardLayout',

  components: {
    BaseSidebar,
  },

  watch: {
    $route: {
      handler() {
        const el = document.querySelector('main');
        el.scrollTop = el.scrollHeight;
        setTimeout(() => {
          el.scrollTop = 0;
        }, 500);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="css" scoped>
.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
}

.dashboard-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
  overflow: scroll;
  overscroll-behavior-y: scroll;
}
</style>
